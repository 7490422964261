import React, { useContext } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

export default function AlertDialog({ open, handleClose, title, desc }) {
  const history = useHistory();
  const auth = useContext(AuthContext);

  const logoutHandler = () => {
    history.push("/");
    auth.userLogIn(false, null);
    window.sessionStorage.removeItem("token");
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Box align="center">
            <Typography
              variant="h3"
              style={{ color: "#404040", fontSize: "25px" }}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            style={{
              color: "rgb(0, 0, 0)",
              fontSize: "16px",
              margin: "20px 0px",
              textAlign: "center",
            }}
          >
            {" "}
            {desc}
          </Typography>

          <Box mb={2} align="center">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              style={{ padding: "7px 23px" }}
            >
              Cancel
            </Button>
            {
              title === "Logout" ? <Button
                style={{ marginLeft: "13px", padding: "7px 20px" }}
                variant="outlined"
                color="primary"
                onClick={logoutHandler}
              >
                Confirm
              </Button> : <Button
                style={{ marginLeft: "13px", padding: "7px 20px" }}
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Confirm
              </Button>
            }
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
