import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import { getDataHandlerAPI } from "src/apiConfig/service";
import { calculateTimeLeft } from "src/utils/index";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");

  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  console.log("lsjfhkjsdfh", isLogin);
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();

  const getUserProfileDatahandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.profile,
        headers: {
          token: token,
        },
      });
      console.log("res-----", res);
      if (res.data.statusCode === 200) {
        setUserData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.sessionStorage.removeItem("token");
        }
      }
    }
  };
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 5000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      getUserProfileDatahandler(window.sessionStorage.getItem("token"));
    }
  }, []);
  let data = {
    userLoggedIn: isLogin,
    userData,
    timeLeft,
    setTimeLeft,
    setEndtime,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
