import React from 'react';
import { makeStyles, Box, Typography, IconButton, withStyles, MenuItem, Menu, Hidden, Grid } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useHistory } from 'react-router-dom';
import FilterModal from './FilterModal';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';


const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            // backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    }
}))(MenuItem);
const StyledMenu = withStyles({
    paper: {
        backgroundColor: "#FFFFFF !important",
        minWidth: "120px !important",
        boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.16)",
        borderRadius: "5px"
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
        "& h4": {
            fontWeight: "600"
        },
        "& h6": {
            fontWeight: "600",
            padding: "15px"
        }
    },
    iconbutton: {
        position: "relative",

        [theme.breakpoints.down("xs")]: {
            marginRight: "0px",
        },
        "& div": {
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            backgroundColor: "#6FCFB9",
            position: "absolute",
            top: "7px",
            right: "8px",
        },
    },
    addStoryMenu: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        padding: "5px 0px",
        "& figure": {
            margin: "0px",
            width: "15px",
            height: "15px",
            "& img": {
                width: "100%"
            }
        },
        "& p": {
            marginLeft: "10px"
        }
    },
    MarketNavbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .iconButtons": {
            width: "20px",
            display: "flex", alignItems: "center",
        }
    },

}))


function MarketFilterIconHeading({ heading, type }) {
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openShare, setOpenShare] = React.useState(false);
    const [openReport, setOpenReport] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);

    const handleClickOpenFilter = () => {
        setOpenFilter(true);

    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickOpenShare = () => {
        setOpenShare(true);
        setAnchorEl(false);
    };
    const handleClickOpenReport = () => {
        setOpenReport(true);
        setAnchorEl(false);
    };

    return (
        <>

            {
                type === "MarketNavbar" ? (
                    <Box className={classes.MarketNavbar} style={{}}>
                        <Typography variant="h4">{heading}</Typography>
                        <Box className='iconButtons'>
                            <IconButton
                                className={classes.iconbutton}
                                onClick={handleClickOpenFilter}
                            >
                                <FilterListIcon style={{ color: "#6FCFB9", fontSize: "30px" }} />
                            </IconButton>
                               
                            <IconButton  onClick={handleClick} >
                           
                                <MoreHorizIcon />
                            </IconButton>
                        </Box>
                        <FilterModal handleCloseFilter={handleCloseFilter} openFilter={openFilter} />
                        <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <StyledMenuItem onClick={handleClickOpenShare}>
                                <Box className={classes.addStoryMenu}>
                                    <figure>
                                        <img src="images/story/AddPostIcon.png" alt='PostIcon'/>
                                    </figure>
                                    <Typography variant="body2">Post</Typography>
                                </Box>
                            </StyledMenuItem>
                            <StyledMenuItem>
                                <Box className={classes.addStoryMenu} onClick={() => history.push("/create-story")}>
                                    <figure>
                                        <img src="images/story/AddStoryIcon.png" alt='loading' />
                                    </figure>
                                    <Typography variant="body2">Story</Typography>
                                </Box>
                            </StyledMenuItem>
                            <StyledMenuItem onClick={handleClickOpenReport}>
                                <Box className={classes.addStoryMenu}>
                                    <figure>
                                        <img src="images/story/AddPetsIcon.png" alt='PetsIcon' />
                                    </figure>
                                    <Typography variant="body2">Pets</Typography>
                                </Box>
                            </StyledMenuItem>
                        </StyledMenu>
                    </Box>
                ) : (<Grid container spacing={1}>
                    <Hidden smDown>
                        <Grid xs={12} md={5} >
                        </Grid>
                    </Hidden>
                    <Grid xs={12} md={7} >
                        <Box className={classes.root}>
                            <Typography variant="h4">{heading}</Typography>
                            <IconButton
                                className={classes.iconbutton}
                                onClick={handleClick}
                            >
                                <FilterListIcon style={{ color: "#6FCFB9", fontSize: "30px" }} />
                            </IconButton>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {
                                    heading === "Product on Sale" && (
                                        <Typography variant="h6" style={{ padding: "15px", fontWight: "600" }}>Food  Categories</Typography>
                                    )
                                }
                                {
                                    heading === "Pets on sale" && (
                                        <Typography variant="h6" style={{ padding: "15px", fontWight: "600" }}>Pets  Categories</Typography>
                                    )
                                }
                                {
                                    heading === "Services on sale" && (
                                        <Typography variant="h6" style={{ padding: "15px", fontWight: "600" }}>Services  Categories</Typography>
                                    )
                                }
                                <StyledMenuItem onClick={handleClickOpenShare}>
                                    <Box className={classes.addStoryMenu}>
                                        <figure>
                                            <img src="images/story/AddPostIcon.png" alt='PostIcon' />
                                        </figure>
                                        <Typography variant="body2">Post</Typography>
                                    </Box>
                                </StyledMenuItem>
                                <StyledMenuItem>
                                    <Box className={classes.addStoryMenu} onClick={() => history.push("/create-story")}>
                                        <figure>
                                            <img src="images/story/AddStoryIcon.png" alt="StoryIcon"/>
                                        </figure>
                                        <Typography variant="body2">Story</Typography>
                                    </Box>
                                </StyledMenuItem>
                                <StyledMenuItem onClick={handleClickOpenReport}>
                                    <Box className={classes.addStoryMenu}>
                                        <figure>
                                            <img src="images/story/AddPetsIcon.png"  alt='PetsIcon' />
                                        </figure>
                                        <Typography variant="body2">Pets</Typography>
                                    </Box>
                                </StyledMenuItem>
                            </StyledMenu>
                        </Box>
                    </Grid>
                </Grid>)
            }
        </>

    )
}


export default MarketFilterIconHeading;