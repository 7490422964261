/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  Divider,
  List,
  IconButton,
  InputAdornment,
  ListSubheader,
  TextField,
  FormControl,
  makeStyles,
  Slide
} from "@material-ui/core";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Logo from 'src/component/Logo';
import { IoSearchOutline } from "react-icons/io5";
import MarketFilterIconHeading from "src/component/MarketFilterIconHeading";
import { MdDashboard } from "react-icons/md";
import { GiRabbitHead } from "react-icons/gi";
import { FaDog } from "react-icons/fa";
import { FaCat } from "react-icons/fa";
import { GiDogBowl, GiMagickTrick } from "react-icons/gi";
import { GiEgyptianBird } from "react-icons/gi";
import { MdOutlineBathtub } from "react-icons/md";
import { GiMedicines } from "react-icons/gi";
import { BiCut } from "react-icons/bi";
import { TbDogBowl } from "react-icons/tb";
import { GiComb } from "react-icons/gi";
import { FaTeeth } from "react-icons/fa";
import { TbVaccine } from "react-icons/tb";
import { RiFootballFill } from "react-icons/ri";
import { GiBeltBuckles } from "react-icons/gi";
import GlobalSearchField from "src/component/GlobalSearchField";

import NavItem from "./NavItem";
import {
  FaSignOutAlt,
  FaUserCircle,
  FaUser,
  FaRegEdit,
} from "react-icons/fa";

const sections = [
  {
    subheader: "Top Categories",
    items: [
      {
        title: "Browse All",
        icon: MdDashboard,
        href: "/market",
      },
      {
        title: "Products",
        icon: GiDogBowl,
        href: "/market-product",
      },
      {
        title: "Pets",
        icon: FaCat,
        href: "/market-pets",
      },
      {
        title: "Services",
        icon: FaDog,
        href: "/market-services",
      },
    ],
  },
  {
    subheader: "Services Categories",
    items: [
      {
        title: "Browse All",
        icon: MdDashboard,
        href: "/dashboard",
      },
      {

        items: [
          {
            title: "Pets",
            icon: FaUser,
            href: "",
            items: [
              {
                title: "Cat",
                 icon: MdOutlineBathtub,
                href: "/cat",
              },
              {
                title: "Dog",
                 icon: MdOutlineBathtub,
                href: "/dog",
              },
              {
                title: "Rabbit",
                 icon: MdOutlineBathtub,
                href: "/rabbit",
              },
              {
                title: "Bird",
                 icon: MdOutlineBathtub,
                href: "/bird",
              },
            ],
          },
        ],
      },
      {
        title: "Bath",
        icon: MdOutlineBathtub,
        href: "/dashboard",
      },
      {
        title: "Hair",
        icon: BiCut,
        href: "/dashboard",
      },
      {
        title: "Fleas & Tricks",
        icon: GiMagickTrick,
        href: "/dashboard",
      },
      {
        title: "Vaccination",
        icon: TbVaccine,
        href: "/dashboard",
      },
      {
        title: "Teeth",
        icon: FaTeeth,
        href: "/dashboard",
      },
    ],
  },
  {
    subheader: "Product Categories",
    items: [
      {
        title: "Browse All",
        icon: MdDashboard,
        href: "/dashboard",
      },
      {
        title: "Animal Food",
        icon: TbDogBowl,
        href: "/dashboard",
      },
      {
        title: "Grooming",
        icon: GiComb,
        href: "/dashboard",
      },
      {
        title: "Clothing & Accessories",
        icon: GiBeltBuckles,
        href: "/dashboard",
      },
      {
        title: "Toys & Play",
        icon: RiFootballFill,
        href: "/dashboard",
      },
      {
        title: "Wellness & Medicines",
        icon: GiMedicines,
        href: "/dashboard",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} info={item.info} key={key}>
        <img src={item.icon} alt="" /> {item.title}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 200,
    top: 72,
    left: "30px",
    height: "100%",
    background: theme.palette.background.default,
    borderRight: "0",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#6FCFB9",
    },
    "& svg": {
      color: "#6FCFB9",
      fontSize: "20px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);


  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box height="100%" display="flex" flexDirection="column" >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <MarketFilterIconHeading heading="Market" type="MarketNavbar" />
        <Box my={1}>
          <Divider />
        </Box>
        <Box>
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="Search"
              onClick={handleClickOpenEdit}
              type="search"
              placeholder="Search....."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="start" onClick={handleClickOpenEdit}>
                      <IoSearchOutline />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <GlobalSearchField
              handleCloseEdit={handleCloseEdit}
              openEdit={openEdit}
            />
          </FormControl>
        </Box>
        <Box py={2}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={1} align="center">
            <Logo width="175" />
          </Box>
          <Divider />
          <Box p={2}>
            {content}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
