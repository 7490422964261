/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  List,
  makeStyles,
  Avatar,
  Divider,
  Typography,
  Button,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useHistory, Link } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { BiLogOutCircle } from "react-icons/bi";
import { HiOutlineLockClosed } from "react-icons/hi";
import { MdContactMail, MdHelpOutline, MdSettings } from "react-icons/md";
import { AuthContext } from "src/context/Auth";

const sections = [
  {
    title: "View Profile",
    href: "/view-profile",
    icon: MdContactMail,
  },
  {
    title: "Language Settings",
    href: "/select-language",
    icon: MdSettings,
  },
  {
    title: "Help & Support",
    href: "/faq-section",
    icon: MdHelpOutline,
  },
  {
    title: "Change Password",
    href: "/change-password",
    icon: HiOutlineLockClosed,
  },
  {
    title: "Logout",
    href: "/dashboard",
    icon: BiLogOutCircle,
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    position: "absolute",
    right: 0,
    top: 30,
    width: 280,
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
    "@media (max-width: 767px)": {
      width: "37px",
      height: "37px",
    },
  },
  avatarBig: {
    cursor: "pointer",
    width: 150,
    height: 150,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  UserNameClass: {
    display: "flex",
    alignItems: "center",
    "& .UserProfile": {
      width: "50px",
      position: "relative",
      "& .smallImage": {
        margin: "0px",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        position: "absolute",
        bottom: "0px",
        right: "10px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
          mixWidth: "100%",
          width: "auto",
          minHeight: "100%",
        },
      },
    },
    "& h4": {
      fontWeight: "600",
      marginLeft: "10px",
    },
  },
  termsPrivay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      "& a": {
        color: "#918E8E",
      },
    },
  },
  menuButtons: {
    justifyContent: "left",
    textTransform: "capitalize",
    color: "#404040",
    fontSize: "15px",
    lineHeight: "30px",
  },
}));

const UseProfileNavBar = () => {
  const auth = useContext(AuthContext)
  const userData = auth?.userData

  const classes = useStyles();
  const history = useHistory();
  const [rightBar, setRightBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setLoader] = useState(false);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to Logout"}
        />
      )}
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        className="walletbox"
      >
        <Box>
          <Box align="center">
            <Typography variant="h6">Profile</Typography>
          </Box>
          <Box className={classes.UserNameClass}>
            <Box className="UserProfile">
              <Avatar src="images/User.png" />
              <figure className="smallImage">
                <img src="images/Activity.png" />
              </figure>
            </Box>
            {/* <Typography variant="h4">{userData?.name ? userData?.name : "N/A"}</Typography> */}
            <Typography variant="h4">Jatin Dutt</Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <Divider />
        </Box>
        <Box mt={1}>
          {sections.map((section, i) => {
            const Icon = section.icon;
            return (
              <Button
                fullWidth
                className={classes.menuButtons}
                key={i}
                onClick={() => {
                  section.title === "Logout"
                    ? setOpen(true)
                    : history.push(section.href);
                }}
              >
                <Icon color="#BBB8B8" fontSize="20px" />
                &nbsp;&nbsp;{section.title}
              </Button>
            );
          })}
        </Box>
        <Box className={classes.termsPrivay}>
          <Typography variant="body2">
            <Link target="_blank" to="/privacy-policy">
              Privacy Policy
            </Link>{" "}
            &nbsp;&nbsp;{" "}
            <Link target="_blank" to="/terms-conditions">
              Terms & Conditions
            </Link>
          </Typography>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Avatar
        src={data?.imageUrl ? data?.imageUrl : "/images/User.png"}
        className={classes.avatar}
        onClick={() => {
          setRightBar(!rightBar);
        }}
      />
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>
    </>
  );
};

UseProfileNavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default UseProfileNavBar;
