import React from 'react';
import {
    makeStyles, Dialog, DialogContent, IconButton,
    InputAdornment,
    FormControl,
    Typography,
    TextField,
    Box,
    Button
} from '@material-ui/core';
import { GoPlus } from "react-icons/go";
import { FaSmile } from "react-icons/fa";
import { IoImageOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { HiUserAdd } from "react-icons/hi";
import { MdAddLocationAlt } from "react-icons/md";
import { BsFillCameraVideoFill } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
    mainmodalBox: {
        "& .formControl": {
            width: "100%",
            backgroundColor: "transparent",
            border: "none",
            color: "#fff",
            "&:focus-visible": {
                outline: "none",
            },
        },
        "& .addphotos": {
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            padding: "75px 20px",
            background: "#FFFFFF",
            border: "1px dashed #DFDFDF",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "7px",
            // border: "1px dashed",
            cursor: "pointer",
            position: "relative",
            "& input": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
            },
            "& svg": {
                fontSize: "30px",
            },
        },
        "& .addImagegIcon": {
            cursor: "pointer",
            position: "relative",
            "& input": {
                top: 0,
                left: 0,
                position: "absolute",
                width: "100%",
                height: "100%",
                opacity: 0,
            },
            "& svg": {
                color: "#ACACAC",
                fontSize: "22px",
            },
        },
    },
    cancelBtn: {
        position: "absolute",
        top: 0,
        right: 0,
        "& svg": {
            color: "#000",
            fontWeight: "700",
        },
    },
    imgPickerBox: {
        "& p": {
            fontSize: "14px",
            lineHeight: "17px",
            color: "#9E9E9E",
            paddingTop: "20px",
        },
    },
    imageBox: {
        width: "50px",
        height: "50px ",
        paddingRight: " 8px",
    },
    commentControl: {
        "& .MuiInputBase-root": {
            boxShadow: "none !important",
        },

        "& .allIconInput": {
            "@media(max-width:408px)": {
                padding: "4px",
                "& svg": {
                    fontSize: "17px"
                },
            },
        },
    },
}));


function CreatePostModal({ openCreatePost, handleCloseCreatePost, handleClickFriendModal, handleClickOpenModal }) {
    const classes = useStyles();
    return (
        <Dialog
            open={openCreatePost}
            onClose={handleCloseCreatePost}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            className={classes.root}
        >
            <Box align="center">
                <Box>
                    <Typography variant="h6">Create post</Typography>
                </Box>
            </Box>
            <DialogContent>
                <IconButton className={classes.cancelBtn} onClick={handleCloseCreatePost}>
                    <IoClose />
                </IconButton>
                <Box className={classes.mainmodalBox}>
                    <Box className="addphotos">
                        <input type="file" />
                        <Box className={classes.imgPickerBox}>
                            <GoPlus style={{ color: "#6FCFB9" }} />
                            <Typography variant="body2">Add photos/Video here</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" pt={4}>
                        <Box className={classes.imageBox}>
                            <img src="images/Users.png" alt="" />
                        </Box>
                        <FormControl fullWidth className={classes.commentControl}>
                            <TextField
                                className="commentField"
                                fullWidth
                                variant="outlined"
                                placeholder="Write a comment..."
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <IconButton onClick={handleClickOpenModal} className="allIconInput">
                                                <MdAddLocationAlt />
                                            </IconButton>
                                            <IconButton onClick={handleClickFriendModal} className="allIconInput">
                                                <HiUserAdd />
                                            </IconButton>
                                            <IconButton className="allIconInput">
                                                <FaSmile />
                                            </IconButton>
                                            <IconButton className="allIconInput">
                                                <Box className="addImagegIcon">
                                                    <input type="file" accept="image/*" />
                                                    <Box style={{ paddingTop: "5px" }}>
                                                        <IoImageOutline />
                                                    </Box>
                                                </Box>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box mt={3} align="center">
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ padding: "10px 60px" }}
                            onClick={handleCloseCreatePost}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default CreatePostModal;