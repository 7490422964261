import {
  makeStyles,
  Paper,
  Typography,
  Box,
  Dialog,
  Grid,
  Button,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
    "& h6": {
      color: "#000",
      paddingBottom: "10px",
    },
    "& .textBox": {
      display: "flex",
      alignItems: "center",
      padding: "10px 0px",
      "& img": {
        paddingRight: "10px",
      },
    },
  },
  btnBox: {
    maxWidth: "350px"
  }
}));

export default function StoryPreview({ setCreatePhotoStory, openStoryPreview, closeStoryPreview }) {
  const classes = useStyles();
  return (
    <Dialog open={openStoryPreview} onClose={closeStoryPreview} maxWidth="sm" fullWidth >
      <Paper>
        <Box className={classes.mainContainer}>
          <Typography variant="h6">Preview</Typography>

          <Box className="previewBox">
            <img src="images/meta.png" width="100%" alt="" />
          </Box>
          <Box className="textBox">
            <img src="images/tpic.png" alt="" />
            <Typography variant="body2">Add text</Typography>
          </Box>
          <Box pt={2} align="center">
            <Box className={classes.btnBox}>
              <Grid container spacing={2}>
                <Grid item xs={6} lg={6}>
                  <Button variant="contained" fullWidth onClick={closeStoryPreview}>Discard</Button>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Button variant="contained" fullWidth color="secondary" onClick={closeStoryPreview}>
                    Share to story
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}
