import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Addstory from "src/component/Addstory";
import CreatePostModal from "src/component/CreatePostModal";
import TagFriend from "src/component/TagFriend";
import Addlocation from "src/component/Addlocation";
import StoryPreview from "src/views/pages/Dashboard/Story/StoryPreview";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  Dialog,
  withStyles,
  ListItemText,
  Typography,
  MenuItem,
  Menu,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
  Button,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { BsFillChatDotsFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import UseProfileNavBar from "./RightDrawer";
import { IoMdAddCircle } from "react-icons/io";
import Logo from "src/component/Logo";
import { IoClose } from "react-icons/io5";
import { MdNotifications } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { GoSettings } from "react-icons/go";
import GlobalSearchField from "src/component/GlobalSearchField";

const NotificationData = [
  {
    image: "images/dogprofile.png",
    notification: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    time: "1m ago.",
  },
  {
    image: "images/dogprofile.png",
    notification: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ",
    time: "1m ago.",
  },
  {
    image: "images/dogprofile.png",
    notification: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ",
    time: "1m ago.",
  },
  {
    image: "images/dogprofile.png",
    notification: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ",
    time: "1m ago.",
  },
  {
    image: "images/dogprofile.png",
    notification: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ",
    time: "1m ago.",
  },
  {
    image: "images/dogprofile.png",
    notification: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ",
    time: "1m ago.",
  },
  {
    image: "images/dogprofile.png",
    notification: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ",
    time: "1m ago.",
  },
];

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#FFFFFF !important",
    minWidth: "120px !important",
    boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.16)",
    borderRadius: "5px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "12px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#404040",
    padding: "0px 14px",
    letterSpacing: "1px",
    marginLeft: "15px",
    textDecoration: "none",
    lineHeight: "54px",
    display: "flex",
    "& span": {
      display: "flex",
      alignItems: "center",
    },
    "@media (max-width: 1280px)": {
      fontStyle: "normal",
      letterSpacing: "1px",
      lineHeight: "7px",
      color: theme.palette.primary.main,
      padding: "12px 0px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "auto",
    },
    "&.activeMenu": {
      color: "#6fcfb9",
      position: "relative",
      "&:before": {
        content: "' '",
        position: "absolute",
        top: "54px",
        left: " 0px",
        width: "100%",
        height: "5px",
        background: "#6fcfb9",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
    "&:hover": {
      color: "#6fcfb9",
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    height: "30px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    // alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingLeft: "5px",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: 450,
    // background: theme.palette.primary.main,
    height: 450,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    // color: theme.palette.secondary.main,
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
    "& div": {
      height: "5px",
      width: "5px",
      borderRadius: "50%",
      backgroundColor: "#6FCFB9",
      position: "absolute",
      top: "7px",
      right: "8px",
    },
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .leftBox": {
      width: "306px",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
      "& img": {
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0 !important",
        },
      },
    },
    // "& .rightBox": {
    //   width: "calc(100% - 306px)",
    //   [theme.breakpoints.down("md")]: {
    //     width: "calc(100% - 200px)",
    //   },
    //   [theme.breakpoints.down("xs")]: {
    //     width: "calc(100% - 150px)",
    //   },
    //   "& .menubox": {
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "flex-end",
    //   },
    // },
  },
  searchBoxClass: {
    // marginLeft: "-50px",
    // position:"relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },
  notification: {
    margin: "0px 10px",
    "& button": {
      backgroundColor: "#F5F5F5",
      "& svg": {
        color: "#404040",
      },
    },
  },
  addStoryMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: "5px 0px",
    "& figure": {
      margin: "0px",
      width: "15px",
      height: "15px",
      "& img": {
        width: "100%",
      },
    },
    "& p": {
      marginLeft: "10px",
    },
  },
  ProfileNotification: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  imageBox: {
    "& img": {
      width: "25px",
      position: "relative",
      bottom: "15px",
      zIndex: "1",
      left: "23px",
      border: "1px solid #fff",
      borderRadius: "100px",
    },
  },
  notificationbox: {
    display: "flex",
    justifyContent: "space-between",

  },
  typo: {
    padding: " 12px 0px 14px",
    "& p": {
      fontWeight: "600",
      paddingLeft: "15px"

    },

    "& h5": {
      fontSize: "10px",
      fontWeight: "500",
      position: "absolute",
      cursor: "pointer",
      top: "16px",
      right: "15px",
      color: "#56B3E8",
      textDecoration: "underline"
    },
  },
  searchControl: {
    "& .MuiInputBase-root": {
      boxShadow: "none !important",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;

export function TopBarData() {
  const {
    menuul,
    searchBoxClass,
    searchControl,
    searchText,
    ProfileNotification,
    mainheader,
    addStoryMenu,
    iconbutton,
    notification,
    inerrMenu,
    menuButton,
    imageBox,
    notificationbox,
    typo,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [notificationpopup, setNotificationPopup] = React.useState(null);
  const notificationhandleClick = (event) => {
    setNotificationPopup(event.currentTarget);
  };

  const notificationhandleClose = () => {
    setNotificationPopup(null);
  };

  const [openShare, setOpenShare] = React.useState(false);

  const handleClickOpenShare = () => {
    setOpenShare(true);
    setAnchorEl(false);
    setNotificationPopup(false);
  };
  const [openReport, setOpenReport] = React.useState(false);

  const handleClickOpenReport = () => {
    setOpenReport(true);
    setAnchorEl(false);
    setNotificationPopup(false);
  };

  const [openAddStory, setOpenAddStory] = useState(false);
  const [openStoryPreview, setStoryPreview] = useState(false);
  const [openCreatePost, setOpenCreatePost] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleClickCloseModal = () => {
    setOpenModal(false);
  };
  const [friendModal, setFriendModal] = useState(false);

  const handleClickFriendModal = () => {
    setFriendModal(true);
  };
  const handleClickCloseFriendModal = () => {
    setFriendModal(false);
  };

  const handleClickOpenCreatePost = () => {
    setOpenCreatePost(true);
  };

  const handleCloseCreatePost = () => {
    setOpenCreatePost(false);
  };

  const openAddStoryHandler = () => {
    setOpenAddStory(true)
  };

  const closeAddStoryHandler = () => {
    setOpenAddStory(false);
    setStoryPreview(true);
  };

  const closeStoryPreview = () => {
    setStoryPreview(false);
  };



  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const menuText = (
    <nav>
      <ul class={menuul}>
        <li className="activecolor">
          <ul className={inerrMenu}>
            <li>
              <NavLink
                exact
                activeClassName={mobileView ? "" : "activeMenu"}
                className={menuButton}
                to="/home"
              // onClick={() => auth.setcategoryType("CRICKET")}
              >
                {" "}
                Home{" "}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName={mobileView ? "" : "activeMenu"}
                className={menuButton}
                to="/market"
              // onClick={() => auth.setcategoryType("FOOTBALL")}
              >
                {" "}
                Market{" "}
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );

  const searchBox = (
    <Box className={searchBoxClass}>
      <FormControl fullWidth className={searchControl}>
        <TextField

          id="outlined-basic"
          variant="outlined"
          name="Text Field"
          placeholder="Search......"
          onClick={handleClickOpenEdit}
          type="search"
          className={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton edge="start" onClick={handleClickOpenEdit}>
                  <IoSearchOutline />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <GlobalSearchField
          handleCloseEdit={handleCloseEdit}
          openEdit={openEdit}
        />
      </FormControl>
    </Box>
  );

  const ProfileAndNotification = (
    <Box className={ProfileNotification}>
      <Hidden xsDown>
        <IconButton className={iconbutton} onClick={handleClick}>
          <IoMdAddCircle style={{ color: "#6FCFB9", fontSize: "30px" }} />
        </IconButton>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={handleClickOpenCreatePost}>
            <Box className={addStoryMenu}>
              <figure>
                <img src="images/story/AddPostIcon.png" />
              </figure>
              <Typography variant="body2">Post</Typography>
            </Box>
          </StyledMenuItem>
          <StyledMenuItem onClick={openAddStoryHandler}>
            <Box className={addStoryMenu} >
              <figure>
                <img src="images/story/AddStoryIcon.png" />
              </figure>
              <Typography variant="body2">Story</Typography>
            </Box>
          </StyledMenuItem>
          <StyledMenuItem onClick={() => history.push("/add-pets")}>
            <Box className={addStoryMenu}>
              <figure>
                <img src="images/story/AddPetsIcon.png" />
              </figure>
              <Typography variant="body2">Pets</Typography>
            </Box>
          </StyledMenuItem>
        </StyledMenu>
        <IconButton onClick={() => history.push("/chat-history")}>
          <BsFillChatDotsFill style={{ color: "#6FCFB9", fontSize: "30px" }} />
        </IconButton>
      </Hidden>
      <Box className={notification}>
        <IconButton onClick={notificationhandleClick}>
          <MdNotifications />
        </IconButton>
      </Box>
      <StyledMenu
        id="customized-menu"
        anchorEl={notificationpopup}
        keepMounted
        open={Boolean(notificationpopup)}
        onClose={notificationhandleClose}
      >
        <Box className={typo}>
          <Typography variant="body2">Notifications</Typography>

          <Typography variant="h5" onClick={() => history.push("/notification-list")}>See All</Typography>
        </Box>

        <Box style={{ maxHeight: "350px", overflow: "scroll" }}>
          {NotificationData.map((data, i) => {
            return (
              <StyledMenuItem>
                <Box className={notificationbox}>
                  <Box
                    display="flex"

                  >
                    <Box minWidth="50px" pt={1.5}>
                      <Avatar src="images/User.png"></Avatar>
                      <Box className={imageBox}>
                        <img src={data?.image} />
                      </Box>
                    </Box>
                    <Box ml={2} mt={1}>
                      <Typography variant="body2" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "break-spaces", fontSize: "12px" }}>
                        {data?.notification}
                      </Typography>

                      <Typography variant="body1" style={{ color: "#6C6C6C" }}>
                        {data?.time}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <IconButton>
                      <CloseIcon style={{ color: "#6FCFB9" }} />
                    </IconButton>
                  </Box>
                </Box>
              </StyledMenuItem>
            );
          })}
        </Box>
      </StyledMenu>
      <Box mx={1}>
        <UseProfileNavBar />
      </Box>
    </Box>
  );
  return (
    <>
      <Box className={mainheader}>
        <Grid container alignItems="center">
          <Grid item xs={5} md={2}>
            <Box className="leftBox">
              <Logo width="125" />
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item sm={3} md={2} align="left">
              {searchBox}
            </Grid>
            <Grid item xs={4} align="center">
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {menuText}
              </Box>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={7} align="right">
              {ProfileAndNotification}
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item xs={4} align="right">
              {ProfileAndNotification}
            </Grid>
          </Hidden>
        </Grid>
        <CreatePostModal openCreatePost={openCreatePost} handleCloseCreatePost={handleCloseCreatePost} handleClickOpenModal={handleClickOpenModal} handleClickFriendModal={handleClickFriendModal} />
        <Addlocation
          handleClickCloseModal={handleClickCloseModal}
          openModal={openModal}
        />
        <TagFriend
          handleClickCloseFriendModal={handleClickCloseFriendModal}
          friendModal={friendModal}
        />
        <Addstory openAddStory={openAddStory} setOpenAddStory={setOpenAddStory} closeAddStoryHandler={closeAddStoryHandler} />
        <StoryPreview openStoryPreview={openStoryPreview} closeStoryPreview={closeStoryPreview} />
      </Box>
    </>
  );
}
