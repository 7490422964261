import {
  Box,
  Dialog,
  DialogContent,
  Paper,
  IconButton,
  makeStyles,
  Button,
  Typography,
  TextField,
  Grid,
} from "@material-ui/core";
import React from "react";
import { BiCurrentLocation } from "react-icons/bi";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Form, Formik } from "formik";
import * as yep from "yup";
const useStyles = makeStyles((theme) => ({
  CloseIconBox: {
    position: "absolute",
    right: "0px",
    top: "8px",
    "& svg": {
      color: "#000",
    },
  },
  buttonBox: {
    "& p": {
      color: "rgba(158, 158, 158, 0.6)",
      textDecorationLine: "underline",
      padding: "10px 0px",
    },
    "& h6": {
      paddingBottom: "10px",
    },
  },
  SelectBox: {
    maxWidth: "350px",
    padding: "0px 0px 10px",
    minHeight: "50px",
   
    "& .MuiInputBase-input": {
      padding: "10px 7px",
    },
  },
}));

export default function FilterModal({ openFilter, handleCloseFilter }) {
  const classes = useStyles();

  const formInitialSchema = {
    region: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
  };

  const formValidationSchema = yep.object().shape({
    region: yep.string().required("region is required."),
    country: yep.string().required("country is required."),
    state: yep.string().required("state is required."),

    city: yep.string().required("city is required."),
    zipcode: yep.string().required("zipcode number is required."),
  });
  const handleFormSubmit = async (values) => {};

  const [age1, setAge1] = React.useState("10");
  const [age2, setAge2] = React.useState("10");
  const [age3, setAge3] = React.useState("10");
  const [age4, setAge4] = React.useState("10");

  const handleChange1 = (event) => {
    setAge1(event.target.value);
  };
  const handleChange2 = (event) => {
    setAge2(event.target.value);
  };
  const handleChange3 = (event) => {
    setAge3(event.target.value);
  };
  const handleChang4 = (event) => {
    setAge4(event.target.value);
  };
  return (
    <Box>
      <Paper>
        <Dialog
          open={openFilter}
          onClose={handleCloseFilter}
          maxWidth="xs"
          fullWidth
        >
          <Box className={classes.CloseIconBox}>
            <IconButton
              onClick={() => handleCloseFilter(false)}
              className={classes.customizedButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Typography variant="h4">Filter</Typography>
          </Box>
          <DialogContent style={{ padding: "8px 24px 40px" }}>
            <Box className={classes.buttonBox} align="center">
              <Button variant="contained" color="secondary">
                <BiCurrentLocation
                  style={{ fontSize: "25px", marginRight: "10px" }}
                />{" "}
                Search By Your Location
              </Button>
              <Typography variant="body2">or</Typography>
              <Typography variant="h6">Enter the location</Typography>
            </Box>
            <Formik
              initialValues={formInitialSchema}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleFormSubmit(values)}
            >
              {({
                errors,
                handleBlur,

                touched,
                values,
              }) => (
                <Form className={classes.root}>
                  <Box align="center">
                    <Box className={classes.SelectBox}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Select
                          variant="outlined"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          fullWidth
                          style={{ padding: "4px" }}
                          className={classes.textBox}
                          margin="dense"
                          name="region"
                          value={values?.region}
                          error={Boolean(touched.region && errors.region)}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange1(e);
                          }}
                        >
                          <MenuItem value="">Select your region</MenuItem>
                          return (
                          <>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </>
                        </Select>
                        <FormHelperText error>
                          {touched.region && errors.region}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <Box className={classes.SelectBox}>
                   
                      <FormControl className={classes.formControl} fullWidth>
                      <Select
                        variant="outlined"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        style={{ padding: "4px" }}
                        className={classes.textBox}
                        margin="dense"
                        name="country"
                        value={values?.country}
                        error={Boolean(touched.country && errors.country)}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange2(e);
                        }}
                      >
                        <MenuItem value="">Select your country</MenuItem>
                        return (
                        <>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </>
                      </Select>
                      <FormHelperText error>
                        {touched.country && errors.country}
                      </FormHelperText>
                    </FormControl>
                     
                    </Box>
                    <Box className={classes.SelectBox}>
                    <FormControl className={classes.formControl} fullWidth>
                    <Select
                      variant="outlined"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      style={{ padding: "4px" }}
                      className={classes.textBox}
                      margin="dense"
                      name="state"
                      value={values?.state}
                      error={Boolean(touched.state && errors.state)}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange2(e);
                      }}
                    >
                      <MenuItem value="">Select your state</MenuItem>
                      return (
                      <>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </>
                    </Select>
                    <FormHelperText error>
                      {touched.state && errors.state}
                    </FormHelperText>
                  </FormControl>
                    </Box>
                    <Box className={classes.SelectBox}>
                    <FormControl className={classes.formControl} fullWidth>
                    <Select
                      variant="outlined"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      style={{ padding: "4px" }}
                      className={classes.textBox}
                      margin="dense"
                      name="city"
                      value={values?.city}
                      error={Boolean(touched.city && errors.city)}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange2(e);
                      }}
                    >
                      <MenuItem value="">Select your city</MenuItem>
                      return (
                      <>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </>
                    </Select>
                    <FormHelperText error>
                      {touched.city && errors.city}
                    </FormHelperText>
                  </FormControl>
                    </Box>
                    <Box className={classes.SelectBox}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter the zipcode"
                      />
                    </Box>
                    <Box className={classes.SelectBox}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Button variant="contained" fullWidth>
                            Reset
                          </Button>
                        </Grid>

                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            fullWidth
                            color="secondary"
                          >
                            Apply Filter
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </Paper>
    </Box>
  );
}
