import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "src/layouts/LoginLayout/LoginTopBar";
// import Term from "src/views/Auth/Terms-and-condition/Index"
const useStyles = makeStyles((theme) => ({
  left: {
   
  },

  mainbox: {
    maxWidth: "100%",
    width: "auto",
    marginTop:"35px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  root: {
    background: "url('images/loginback.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // height:"calc(100vh - 4px)"
   
  },
  mainbox1: {
    margin: "87px auto 0px",
    paddingBottom:"50px",
    [theme.breakpoints.down("xs")]:{
      margin: "47px auto 0px",
    paddingBottom:"20px",

    }
  },
  mainClass: {
    textAlign: "center",
    "& h1":{
      color: "#000000",
      marginTop:"-27px",
    },
    "& h2": {
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  // content:{
  //    height:"calc(100vh - 175px)",
  //    overflow:"hidden"
  // },

}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box>
      <Box className={classes.root}>
        <TopBar/>
        <Container>
          <Box className={classes.mainbox1}>
            <Grid container >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classes.mainClass}
              >
                <Box>
                  <img
                    src="images/loginimg.png"
                    alt="dummy"
                    className={classes.mainbox}
                  />
                </Box>
                <Box>
                  <Typography variant="h1">Cali’s Dairy</Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2">Find, Connect & Buy</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.left}>
                <Box className={classes.content}>{children}</Box>
              </Grid>
            </Grid>
          </Box>
          </Container>
      </Box>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
