/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import GlobalSearchField from "src/component/GlobalSearchField";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  FormControl,
  TextField,
  Hidden,
  List,
  InputAdornment,
  IconButton,
  ListSubheader,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { IoSearchOutline } from "react-icons/io5";
import Logo from "src/component/Logo";
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import { BsHeartFill } from "react-icons/bs";
import { MdOutlineTrackChanges } from "react-icons/md";
import { MdEmojiEvents } from "react-icons/md";
import { AiFillContacts } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FaDog } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { FaSearchengin } from "react-icons/fa";
import { RiTodoFill } from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import { BsHeartHalf } from "react-icons/all";
import NavItem from "./NavItem";
const sections = [
  {
    items: [
      // {
      //   title: "Product",
      //   icon: BsFillInboxesFill,
      //   href: "/product",
      // },
      // {
      //   title: "Services",
      //   icon: FaDog,
      //   href: "/services",
      // },
      {
        title: "Favorites",
        icon: BsHeartHalf,
        href: "/favorites",
      },
      {
        title: "Pets on List",
        icon: RiTodoFill,
        href: "/pets",
      },
      {
        title: "Interested",
        icon: BsHeartFill,
        href: "/interested",
      },
      {
        title: "Events",
        icon: MdEmojiEvents,
        href: "/events",
      },
      {
        title: "Missing Pets",
        icon: FaSearchengin,
        href: "/missing-pets",
      },
      {
        title: "Tracking ",
        icon: MdOutlineTrackChanges,
        href: "/tracking-devices",
      },
      {
        title: "Rewards",
        icon: GiReceiveMoney,
        href: "/rewards",
      },
      {
        title: "Play Game",
        icon: SportsEsportsIcon,
        href: "/play-game",
      },
      {
        title: "About Us",
        icon: BsFillInfoCircleFill,
        href: "/about-us",
      },
      {
        title: "Contact Us",
        icon: AiFillContacts,
        href: "/contact-us",
      },
    ],
  },
];
const drawerSections = [
  {
    items: [
      {
        title: "Market",
        icon: GiReceiveMoney,
        href: "/market",
      },
      // {
      //   title: "Product",
      //   icon: BsFillInboxesFill,
      //   href: "/product",
      // },
      // {
      //   title: "Services",
      //   icon: FaDog,
      //   href: "/services",
      // },
      {
        title: "Favorites",
        icon: BsHeartHalf,
        href: "/favorites",
      },
      {
        title: "Pets on List",
        icon: RiTodoFill,
        href: "/pets",
      },
      {
        title: "Interested",
        icon: BsHeartFill,
        href: "/interested",
      },
      {
        title: "Events",
        icon: MdEmojiEvents,
        href: "/events",
      },
      {
        title: "Missing Pets",
        icon: FaSearchengin,
        href: "/missing-pets",
      },
      {
        title: "Tracking",
        icon: MdOutlineTrackChanges,
        href: "/tracking-devices",
      },
      {
        title: "Rewards",
        icon: GiReceiveMoney,
        href: "/rewards",
      },

      {
        title: "About Us",
        icon: BsFillInfoCircleFill,
        href: "/about-us",
      },
      {
        title: "Contact Us",
        icon: AiFillContacts,
        href: "/contact-us",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 200,
    top: 72,
    left: "30px",
    height: "100%",
    background: theme.palette.background.default,
    borderRight: "0",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#6FCFB9",
    },
    "& svg": {
      color: "#6FCFB9",
      fontSize: "20px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [openSearch, setOpenSearch] = React.useState(false);

  const handleClickOpenSearch = () => {
    setOpenSearch(true);
  };

  const handleCloseSearch = () => {
    setOpenSearch(false);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  const drawerContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={1} align="center">
            <Logo width="175" />
          </Box>
          <Divider />
        <Box p={2} >
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="Search"
              onClick={handleClickOpenSearch}
              type="search"
              placeholder="Search....."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="start" onClick={handleClickOpenSearch}>
                      <IoSearchOutline />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <GlobalSearchField
              handleCloseEdit={handleCloseSearch}
              openEdit={openSearch}
            />
          </FormControl>
        </Box>
        <Box>
          {drawerSections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
