import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { GoPlus } from "react-icons/go";
import { FaSmile } from "react-icons/fa";
import { IoImageOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { BsFillCameraVideoFill } from "react-icons/bs";
import SendIcon from "@material-ui/icons/Send";
const useStyles = makeStyles((theme) => ({
  mainmodalBox: {
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& .addphotos": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      padding: "75px 20px",
      background: "#FFFFFF",
      border: "1px dashed #DFDFDF",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "7px",
      // border: "1px dashed",
      cursor: "pointer",
      position: "relative",
      "& input": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
      },
      "& svg": {
        fontSize: "30px",
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#000",
      fontWeight: "700",
    },
  },
  imgPickerBox: {
    "& p": {
      fontSize: "14px",
      lineHeight: "17px",
      color: "#9E9E9E",
      paddingTop: "20px",
    },
  },
  imageBox: {
    width: "50px",
    height: "50px ",
    paddingRight: " 8px",
  },
  btn1: {
    height: "47px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
}));

export default function Addstory({ closeAddStoryHandler, openAddStory, setOpenAddStory }) {
  const classes = useStyles();
  return (
    <Box>
      <Dialog
        open={openAddStory}
        onClose={() => setOpenAddStory(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Box display="flex" alignItems="center">
          <Avatar src="images/Users.png" />
          <Box ml={1.5}>
            <Typography variant="h5">Jatin Dutt</Typography>
            <Typography variant="body1" style={{ color: "#9E9E9E" }}>
              15 min
            </Typography>
          </Box>
        </Box>
        <IconButton
          className={classes.cancelBtn}
          onClick={closeAddStoryHandler}
        >
          <IoClose />
        </IconButton>
        <DialogContent>
          <Box className={classes.mainmodalBox}>
            <Box className="addphotos">
              <input type="file" />
              <Box className={classes.imgPickerBox}>
                <GoPlus style={{ color: "#6FCFB9" }} />
                <Typography variant="body2">Add photos/Video here</Typography>
              </Box>
            </Box>
            <Box display="flex" pt={4}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Write a comment..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <FaSmile />
                      </IconButton>
                      <IconButton>
                        <IoImageOutline />
                      </IconButton>
                      <IconButton>
                        <BsFillCameraVideoFill />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box ml={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn1}
                >
                  <SendIcon />
                </Button>
              </Box>
            </Box>
            <Box mt={3} align="center">
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: "10px 60px" }}
                onClick={closeAddStoryHandler}
              >
                Post
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>



  );
}
