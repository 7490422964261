import {
  makeStyles,
  Dialog,
  Box,
  Avatar,
  Button,
  Typography,
  DialogContent,
  Container,
  IconButton,
  TextField,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { GoSettings } from "react-icons/go";
import { IoClose } from "react-icons/io5";

const useStyles = makeStyles(() => ({
  mainconatiner: {
    height: "calc(100vh - 90px)",
  },
  searchBoxmodal: {
    position: "absolute",
    top: "0px",
    right: "8px",
    "& svg": {
      color: "#000",
    },
  },
  IconBox: {
    "& svg": {
      color: "#000",
      transform: "rotate(90deg)",
    },
  },
  dflex: {
    display: "flex",
    alignItems: "unset",
  },
  dflex1: {
    display: "flex",
    alignItems: "self-start",
    justifyContent: "space-between",
  },

  imageBox: {
    "& img": {
      width: "25px",
      position: "relative",
      bottom: "15px",
      zIndex: "1",
      left: "23px",
      border: "1px solid #fff",
      borderRadius: "100px",
    },
  },
  GlobalSearchControl: {
    "& .MuiInputBase-root": {
      boxShadow: "none !important",
    },
  },
}));
export default function GlobalSearchField({ openEdit, handleCloseEdit }) {
  const {
    IconBox,
    searchBoxmodal,
    imageBox,
    mainconatiner,
    dflex,
    GlobalSearchControl,
    dflex1,
    searchBoxcontainer,
  } = useStyles();
  return (
    <Dialog
      open={openEdit}
      onClose={handleCloseEdit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <Box className={searchBoxmodal}>
        <IconButton onClick={() => handleCloseEdit(false)}>
          <IoClose />
        </IconButton>
      </Box>
      <DialogContent>
        <Container maxWidth="sm">
          <Box className={mainconatiner}>
            <Box className={searchBoxcontainer} pt={3}>
              <FormControl fullWidth className={GlobalSearchControl}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Search for People, Pets, Products, Services"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <IoSearchOutline />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Box className={IconBox}>
                            <GoSettings />
                          </Box>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              {/* User data------------ */}
              <Box pt={2}>
                <Box className={dflex1}>
                  <Box className={dflex}>
                    <Box minWidth="65px">
                      <Avatar src="images/girl.png"></Avatar>
                      <Box className={imageBox}>
                        <img src="images/User.png" alt="" />
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">Rohit Kumar</Typography>
                      <Typography variant="body2">@rohit</Typography>
                    </Box>
                  </Box>
                  <Button variant="contained" color="secondary">
                    Follow
                  </Button>
                </Box>
                <Box className={dflex1}>
                  <Box className={dflex}>
                    <Box minWidth="65px">
                      <Avatar src="images/girl.png"></Avatar>
                      <Box className={imageBox}>
                        <img src="images/User.png" alt="" />
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">Rohit Kumar</Typography>
                      <Typography variant="body2">@rohit</Typography>
                    </Box>
                  </Box>
                  <Button variant="contained" color="secondary">
                    Follow
                  </Button>
                </Box>
              </Box>
              {/* User data------------ */}
            </Box>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
