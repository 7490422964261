import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MarketLayout from "src/layouts/DashboardLayout/indexMarket";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LoginButtons")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Login")),
  },
  {
    exact: true,
    path: "/reset-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetOtp")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/select-language",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SelectLanguages/SelectLanguageComponent")
    ),
  },
  {
    exact: true,
    path: "/check-email",
    // guard:true,
    component: lazy(() => import("src/views/auth/signup/CheckEmail")),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/home",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/comment",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Comment")),
  },
  {
    exact: true,
    path: "/view-profile",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile")),
  },
  {
    exact: true,
    path: "/add-pets",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MissingPets/AddPets")),
  },
  {
    exact: true,
    path: "/missing-pets",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MissingPets")),
  },
  {
    exact: true,
    path: "/missing-pets-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MissingPets/MissingPetsDetails")
    ),
  },
  {
    exact: true,
    path: "/add-edit-missing-pets",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MissingPets/AddEditMissingPets")
    ),
  },
  {
    exact: true,
    path: "/product",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Product")),
  },
  {
    exact: true,
    path: "/market-product",
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Product/MarketProduct")),
  },
  {
    exact: true,
    path: "/pets",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Pets")),
  },
  {
    exact: true,
    path: "/market-pets",
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Pets/MarketPets")),
  },
  {
    exact: true,
    path: "/services",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Services")),
  },
  {
    exact: true,
    path: "/market-services",
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Services/MarketServices")),
  },
  {
    exact: true,
    path: "/rewards",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Rewards/Rewards")),
  },
  {
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Rewards/TransactionHistory")),
  },
  {
    exact: true,
    path: "/notification-list",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Notification")),
  },
  {
    exact: true,
    path: "/unread-notification",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Notification/UnreadNotification")
    ),
  },
  {
    exact: true,
    path: "/notification",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Notification/Notification")),
  },
  {
    exact: true,
    path: "/market",
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Market/Market")),
  },
  {
    exact: true,
    path: "/pet-description",
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Market/Pets/PetDescription")),
  },
  {
    exact: true,
    path: "/product-description",
    layout: MarketLayout,
    component: lazy(() =>
      import("src/views/pages/Market/Pets/ProductDescription")
    ),
  },
  {
    exact: true,
    path: "/pet-service",
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Market/Pets/PetService")),
  },


  {
    exact: true,
    path: "/subscriber-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/SubscriberList")),
  },
  {
    exact: true,
    path: "/trending-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/TrendingList")),
  },
  {
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPage/TransactionHistory")
    ),
  },
  {
    exact: true,
    path: "/chat-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/ChatList")),
  },
  {
    exact: true,
    path: "/events",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event")),
  },
  {
    exact: true,
    path: "/event-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/EventDetails")),
  },
  {
    exact: true,
    path: "/event-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/EventList")),
  },
  {
    exact: true,
    path: "/add-event",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/AddEvent")),
  },
  {
    exact: true,
    path: "/play-game",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PlayGame")),
  },
  {
    exact: true,
    path: "/tracking-devices",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TrackingDevices")),
  },
  {
    exact: true,
    path: "/faq-section",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPage/FAQ")),
  },
  {
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/Notification")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AboutUs")),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ContactUs")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/edit-pet-post",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/Editpetpost")),
  },
  {
    exact: true,
    path: "/favorites",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Favorite")),
  },
  {
    exact: true,
    path: "/interested",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Interested")),
  },
  // {
  //   exact: true,
  //   path: "/create-story",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Story")),
  // },
  {
    exact: true,
    path: "/story-preview",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Story/StoryPreview")
    ),
  },
  {
    exact: true,
    path: "/story-text",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Story/StoryText")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
