export const baseurl = "http://172.16.1.132:1957"; //local URL
// export const baseurl = "https://node-calisdiary.mobiloitte.com"; //staging URL

let user = `${baseurl}/api/v1/user`;

const Apiconfigs = {
  profile: `${user}/profile`,
  signup: `${user}/signup`,
  verifyOTP: `${user}/verifyOTP`,
  resendOTP: `${user}/resendOTP`,
  login: `${user}/login`,
  editProfile: `${user}/editProfile`,

};
export default Apiconfigs;
